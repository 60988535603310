import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import moment from 'moment';

import Layout from '../layout/layout';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';

import { PressContext } from './press.gatsby';
import { Helmet } from 'react-helmet';
import { TopNav } from '../layout';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { LocaleLink } from '../common/links/LocaleLink';
import { Press } from './press.interface';
import PressReleaseContactInfo from '../press-releases/components/press-release-contact-info';
import { PressAward } from './press-awards.interface';
import { MainFooter } from '../layout/footer/footer.interfaces';
import { ContentfulNavigation } from '../../contentful/content-types/navigation';

interface PressQueryResult {
  pressCoverage: {
    totalCount: number;
    nodes: Press[];
  };
  pressAwards: {
    totalCount: number;
    nodes: PressAward[];
  };
  navData: TopNav;
  redwoodNavData: ContentfulNavigation;
  redwoodRightNavData: ContentfulNavigation;
  utilityNavData: ContentfulNavigation;
  footerData: MainFooter;
}

export interface PressProps extends GatsbyTemplateProps<PressContext & AppContextProps> {
  data: PressQueryResult; // passed from gatsby results from graphql pageQuery
}

export const pageQuery = graphql`
  query($yearStart: Date!, $yearEnd: Date!, $locale: String!) {
    pressCoverage: allPressCoverage(
      filter: { orderDate: { gte: $yearStart, lte: $yearEnd }, locale: { eq: $locale } }
      sort: { fields: orderDate, order: DESC }
    ) {
      nodes {
        id
        pressCoverageLinkText
        pressCoverageUrlLink
        pressCompanyName
        orderDate
      }
    }
    pressAwards: allPressAwards(
      filter: { locale: { eq: $locale } }
      limit: 4
      sort: { fields: orderDate, order: DESC }
    ) {
      nodes {
        id
        link
        orderDate
        title
      }
    }
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
  }
`;

const buildNavLinks = (years: number[], selectedYear: number) =>
  years.map((year, index) => {
    const currentYear = moment(new Date()).year();
    const linkYear = currentYear === year ? '' : `${year}/`;
    const linkClass = selectedYear === year ? 'docmenu__item--active' : '';
    return (
      <li key={`yearLink${index}`} className="docmenu__document">
        <LocaleLink className={linkClass} to={`/press/` + linkYear}>
          {year}
        </LocaleLink>
      </li>
    );
  });

const PressTemplate: FC<PressProps> = ({ data, pageContext }) => {
  const pressGroups = data.pressCoverage.nodes.reduce((prev, curr) => {
    const m = moment(curr.orderDate);
    const month = m.month();
    if (!prev[month]) {
      prev[month] = [];
    }
    prev[month].push(curr);
    return prev;
  }, {} as any);

  const pressItems = Object.keys(pressGroups)
    .reverse()
    .map(m => {
      return [
        <div className="marketing-h3">
          {moment(pressGroups[m][0].orderDate).format('MMMM YYYY')}
        </div>,
        ...pressGroups[m].map((p: Press) => {
          return (
            <div className="marketing-block__press-piece" key={p.id}>
              <div>
                <strong>{p.pressCompanyName}</strong> ·{' '}
                {moment(p.orderDate).format('MMMM DD, YYYY')}
              </div>
              <LocaleLink variant="anchor" to={p.pressCoverageUrlLink}>
                {p.pressCoverageLinkText}
              </LocaleLink>
            </div>
          );
        }),
      ];
    })
    .flat();

  const recentAwards = [
    ...data.pressAwards.nodes.map(a => {
      return (
        <li key={a.id} className="docmenu__document">
          <LocaleLink variant="anchor" to={a.link}>
            {moment(a.orderDate).format('YYYY')} {a.title}
          </LocaleLink>
        </li>
      );
    }),
    <li key="viewMoreLink" className="docmenu__document">
      <LocaleLink variant="anchor" to={'/press-awards'}>
        {`View All >`}
      </LocaleLink>
    </li>,
  ];
  return (
    <Layout
      redwoodNavData={data.redwoodNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      utilityNavData={data.utilityNavData}
      pageContext={pageContext}
      topNavData={data.navData}
      footerData={data.footerData}
    >
      <Helmet>
        <title>Cloudflare | Press Highlights</title>
        <meta name="title" content={`Press Highlights ${pageContext.selectedYear}`} />
        <meta
          name="description"
          content={`We list global press coverage about Cloudflare on this page.`}
        />
      </Helmet>
      <section className="tile tile--borderless">
        <div className="tile__content tile__content--fixed">
          <div className="grid">
            <div className="grid__container">
              <div className="grid__item grid__item--two-thirds">
                <h1 className="marketing-h1 text-left--desktop">
                  Press Highlights {pageContext.selectedYear}
                </h1>
                <div>
                  <div className="marketing-block__press">{pressItems}</div>
                </div>
              </div>
              <div className="grid__item grid__item--third">
                <PressReleaseContactInfo variant="press-releases" />
                <div className="docmenu">
                  <div className="docmenu__heading js-docmenu-toggle">Archive</div>
                  <div className="docmenu__separator"></div>
                  <ul className="docmenu__group">
                    {buildNavLinks(pageContext.years, pageContext.selectedYear)}
                  </ul>
                </div>
                <div className="docmenu">
                  <div className="docmenu__heading js-docmenu-toggle">Recent Awards We've Won</div>
                  <div className="docmenu__separator"></div>
                  <ul className="docmenu__group">{recentAwards}</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PressTemplate;
