import React, { FC } from 'react';
import { Link } from 'gatsby';
import { LocaleLink } from '../../common/links/LocaleLink';
import { useTranslations } from '../../../common/hooks/useTranslations';

export type ContactVariant = 'press-page' | 'press-releases';

export interface PressReleaseContactInfoProps {
  variant?: ContactVariant;
}

const PressReleaseContactInfo: FC<PressReleaseContactInfoProps> = ({
  variant = 'press-releases',
}) => {
  const translate = useTranslations();
  const ButtonVarient = () =>
    variant === 'press-page' ? (
      <LocaleLink to="/press/" className="button button--inline button--blue">
        {translate('Press Page')}
      </LocaleLink>
    ) : (
      <LocaleLink to="/press-releases/" className="button button--inline button--blue">
        {translate('Press Releases')}
      </LocaleLink>
    );
  return (
    <div className="marketing-block__name" itemScope itemType="http://schema.org/Person">
      <div className="text--bold">Press Contact Information</div>
      <div className="text--light" itemProp="name">
        Daniella Vallurupalli
      </div>
      <div className="text--light" itemProp="telephone">
        +1 650-741-3104
      </div>
      <div className="text--light" itemProp="email">
        <a href="mailto:press@cloudflare.com">press@cloudflare.com</a>
      </div>
      <div className="button-container">
        <ButtonVarient />
      </div>
    </div>
  );
};

export default PressReleaseContactInfo;
